import { useState, useCallback, useEffect } from 'react';

import { RFQ_QUERY } from 'shared-components/queries/graphql';
import { Method, Service } from 'shared-components/configuration';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { useSWCallbackSignEvent, useSWCallbackStateEvent } from 'shared-components/providers/SWEventProvider';
import { PendingPaymentType } from 'shared-components/types/PendingPaymentType';
import { convertUTCtoCET } from 'shared-components/utils';

type RfqHookProps = {
  pid?: string;
  txRef?: string;
  first?: number;
  last?: number;
  after?: string;
  before?: string;
  operationId?: string;
  clientPid?: string;
  clientIban?: string;
  fromDate?: string;
  toDate?: string;
  eurAmount?: string;
  sekAmount?: string;
};
const useRfqs = () => {
  const [filters, setFilters] = useState<any>({});
  const [rfqList, setRfqList] = useState<any>([]);
  const [csvList, setCsvList] = useState<any>({});
  const [csvGenerator, setCsvGenerator] = useState<boolean>(false);

  const rfqs = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'rfq',
    data: {
      query: RFQ_QUERY()
    },
    auto: false,
    onResponse: (data: any) => {
      const rfq = {
        data: {},
        pageInfo: {},
        totalCount: 0
      };
      rfq.data = data?.edges?.map((value: any) => {
        const current = value.node;
        try {
          current.kycData = JSON.parse(current.kycData);
        } catch {
          //
        }
        return current;
      });
      rfq.pageInfo = data?.pageInfo;
      rfq.totalCount = data.totalCount;
      //        .sort((a: any, b: any) => (a.createdAt > b.createdAt ? -1 : 1));
      setRfqList(rfq);
    }
  });

  const rfqForCsv = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'rfqCsv',
    data: {
      query: RFQ_QUERY('rfqCsv')
    },
    auto: false,
    onResponse: (data: any) => {
      const rfq = {
        data: {},
        pageInfo: {},
        totalCount: 0
      };
      rfq.data = data?.edges?.map((value: any) => {
        const current = value.node;
        try {
          current.kycData = JSON.parse(current.kycData);
        } catch {
          //
        }
        return current;
      });
      rfq.pageInfo = data?.pageInfo;
      rfq.totalCount = data.totalCount;
      //        .sort((a: any, b: any) => (a.createdAt > b.createdAt ? -1 : 1));
      setCsvList(rfq);
      setCsvGenerator(false);
    },
    onErrors: (_) => {
      setCsvGenerator(false);
    }
  });
  useSWCallbackStateEvent((data: any) => {
    const operationProgress = data.operationProgress;
    if (operationProgress.state !== 'running') {
      return;
    }
    if (
      operationProgress.currentTask.includes('resolve merchant by id') ||
      operationProgress.currentTask.includes('select user account')
    )
      fetchList({});
  });

  useSWCallbackSignEvent((data: any) => {
    const signState = data.piSignState;
    const operationId = data.operationId;
    const rfq = rfqList?.find((value: PendingPaymentType) => {
      return value.operationId === operationId;
    });
    if (rfq.status?.toLowerCase() !== signState.status.toLowerCase()) {
      rfqs.execute();
    }
  });

  const fetchList = useCallback(
    ({
      pid,
      first,
      last,
      after,
      before,
      txRef,
      operationId,
      clientPid,
      clientIban,
      fromDate,
      toDate,
      eurAmount,
      sekAmount
    }: RfqHookProps) => {
      setRfqList({});
      rfqs.execute({
        variables: {
          txRef,
          pid,
          first,
          last,
          after,
          before,
          operationId,
          clientPid,
          clientIban,
          fromDate,
          toDate,
          eurAmount,
          sekAmount
        }
      });
    },
    [rfqs]
  );

  const generateCsv = useCallback(
    (filters: RfqHookProps) => {
      setFilters(filters);
      setCsvGenerator(true);
      rfqForCsv.execute({
        variables: filters
      });
    },
    [rfqForCsv]
  );

  useEffect(() => {
    if (csvList?.data === undefined || csvList?.data?.length === 0) {
      return;
    }
    const header = [
      'Id',
      'Date',
      'CompletedDate',
      'Status',
      'ClientId',
      'Amount',
      'Currency',
      'ActualAmount',
      'ActualCurrency',
      'ExternalReference',
      'Provider',
      'Debtor',
      'RemittanceInformation'
    ];
    const columnCount = header.length;
    const data = csvList?.data?.map((record: any) => [
      record.id || '',
      convertUTCtoCET(record.createdAt) || '',
      convertUTCtoCET(record.settledAt) || '',
      record.status || '',
      record.merchantInternalId || '',
      record.eurRequest === record.sekDeposit
        ? record.sekDeposit?.toFixed(2) ?? '0'
        : record.eurRequest?.toFixed(2) ?? '0',
      record.eurRequest === record.sekDeposit ? 'SEK' : 'EUR',
      record.sekDeposit === undefined ? '' : record.sekDeposit?.toFixed(2) ?? '0',
      'SEK',
      record.merchantPiId || '',
      record.bankName || 'UNKNOWN',
      record.clientIban || record.clientBban,
      record.txRef
    ]);
    const fullData = data?.map((row: any) => {
      if (row.length < columnCount) {
        const missingFields = columnCount - row.length;
        return [...row, ...Array.from({ length: missingFields }, () => '')];
      }
      return row;
    });

    const csvData = [header, ...fullData];
    const csvContent = csvData.map((row) => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    let filename = 'deposits_all.csv';

    if (filters !== undefined && filters?.fromDate !== undefined && filters?.toDate !== undefined) {
      filename = `deposits_${filters.fromDate}-${filters.toDate}.csv`;
    }

    link.setAttribute('download', filename);
    document.body.append(link);
    link.click();
    link.remove();
    setCsvList({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvList]);
  return { data: rfqList, fetchList, generateCsv, csvGenerator };
};

export default useRfqs;
