import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, FloatingLabel, Form, Modal } from 'react-bootstrap';

import clsx from 'clsx';

import useSWQuery from 'shared-components/hooks/useSWQuery';
import { Method, Service } from 'shared-components/configuration';
import { ADD_SUB_MERCHANT } from 'shared-components/queries/mutations';
import { useMerchants } from 'contexts/GraphQL';

import style from './style.module.scss';

interface Props {
  children?: React.ReactNode;
  visible: boolean;
  merchantId: string;
  handleClose?: () => void;
  onSuccess?: () => void;
}

const SubMerchantAddModal = ({ handleClose, visible, onSuccess, merchantId }: Props) => {
  const [alertVisible, setAlertVisible] = useState(false);
  const { merchants, subMerchants } = useMerchants();
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const personIdRef = useRef<HTMLInputElement>(null);
  const merchantsRef = useRef<HTMLSelectElement>(null);

  const query = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'addSubMerchant',
    data: {
      query: ADD_SUB_MERCHANT
    },
    auto: false,
    onResponse: (data: any) => {
      if (data.success === true) {
        onSuccess?.();
      }
    }
  });

  useEffect(() => {
    if (visible) {
      if (merchantId.length === 0 && personIdRef.current !== undefined) {
        personIdRef.current!.readOnly = false;
      }
      setAlertVisible(false);
    }
  }, [merchantId.length, visible]);

  useEffect(() => {
    if (
      visible === false ||
      merchantId.length === 0 ||
      nameRef.current === undefined ||
      emailRef.current === undefined ||
      personIdRef.current === undefined ||
      merchantsRef.current === undefined
    )
      return;
    const subMerchantData = subMerchants.find((value) => value.id === merchantId);
    if (subMerchantData.length === 0) return;
    nameRef.current!.value = subMerchantData.name;
    personIdRef.current!.value = subMerchantData.id;
    personIdRef.current!.readOnly = true;
    emailRef.current!.value = subMerchantData.email;
    for (const option of merchantsRef.current!.options) {
      const merchantFound = subMerchantData.merchants.find((merchant: any) => merchant.id === option.value);
      if (merchantFound !== undefined) {
        option.selected = true;
      }
    }
  }, [merchantId, subMerchants, visible]);

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();
      setAlertVisible(false);
      const name = event.currentTarget.elements.name.value;
      const email = event.currentTarget.elements.email.value;
      const personId = event.currentTarget.elements.personId.value;
      const options = event.currentTarget.elements.merchantIds.selectedOptions;
      const merchantIds = [...options].map(({ value }) => value);
      const accessRights = {
        can_view_clients: true,
        can_edit_clients: false,
        can_initiate_payouts: false,
        can_view_transactions: true
      };
      query.execute({
        variables: {
          name: name,
          email: email,
          personId: personId,
          merchantIds: merchantIds,
          accessRights: JSON.stringify(accessRights)
        }
      });
    },
    [query]
  );

  return (
    <Modal show={visible} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <span className={style.header}>Add Sub Merchant</span>
        </Modal.Header>
        <Modal.Body>
          <center>
            <FloatingLabel controlId="name" label="Name" className={clsx(style.floatingLabel, 'mb-2')}>
              <Form.Control type="text" size="sm" placeholder="Name" ref={nameRef} />
            </FloatingLabel>

            <FloatingLabel controlId="email" label="Email address" className={clsx(style.floatingLabel, 'mb-2')}>
              <Form.Control type="email" size="sm" placeholder="" ref={emailRef} />
            </FloatingLabel>
            <FloatingLabel controlId="personId" label="Person ID" className={clsx(style.floatingLabel, 'mb-2')}>
              <Form.Control type="text" size="sm" placeholder="" ref={personIdRef} />
            </FloatingLabel>
            <Form.Group as={Col} controlId="merchantIds">
              <Form.Label>Merchants</Form.Label>
              <Form.Control as="select" multiple ref={merchantsRef}>
                {merchants.map((value, key) => {
                  return (
                    <option key={key} value={atob(value.id!).split(':').at(1)}>
                      {value.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </center>
          <Alert variant={'danger'} show={alertVisible}>
            Error while adding merchant
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Add merchant</Button>
          <Button type="reset">Reset form</Button>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default SubMerchantAddModal;
