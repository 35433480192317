import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

import jsPDF from 'jspdf';

import { Method, Service } from 'shared-components/configuration';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { INVOICE_BODY, LIST_INVOICES } from 'shared-components/queries/graphql';
import { MARK_INVOICE_AS_PAID } from 'shared-components/queries/mutations';

const Invoices = () => {
  const count = 100;
  const [offset, setOffset] = useState(0);
  const [invoices, setInvoices] = useState([]);
  const [downloadDisabled, setDownloadDisabled] = useState<boolean>(false);
  const [currentInvoice, setCurrentInvoice] = useState<any>({});
  const [invoiceData, setInvoiceData] = useState<any>([]);

  const _markAsPaidQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'markInvoicePaid',
    data: {
      query: MARK_INVOICE_AS_PAID,
      variables: {}
    },
    auto: false,
    onResponse: (data: any) => {
      console.log(data);
    }
  });

  const invoiceBodyQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'invoiceBody',
    data: {
      query: INVOICE_BODY,
      variables: {}
    },
    auto: false,
    onResponse: (data: any) => {
      setDownloadDisabled(false);
      setInvoiceData((old: any) => [...old, ...data.entries]);
      setOffset(data.offset + data.count);
    }
  });

  const invoicesQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'invoices',
    data: {
      query: LIST_INVOICES,
      variables: {
        start: Math.floor((Date.now() - 120 * 24 * 60 * 60 * 1000) / 1000)
      }
    },
    auto: false,
    onResponse: (data: any) => {
      setInvoices(data);
    }
  });

  useEffect(() => {
    invoicesQuery.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentInvoice?.bodyId !== undefined && invoiceData.length < currentInvoice.count) {
      invoiceBodyQuery.execute({ variables: { bodyId: currentInvoice.bodyId, offset: offset, count: count } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInvoice, offset]);

  useEffect(() => {
    if (currentInvoice?.bodyId !== undefined && currentInvoice.count === invoiceData.length) {
      const doc = new jsPDF({ format: 'a4', unit: 'px', orientation: 'portrait', compress: true });
      //doc.setFont('Inter-Regular', 'normal');
      let recordData = '';
      let count = 0;
      for (const element of invoiceData as any) {
        count += 1;
        const amount = element.amount;
        const date = new Date(element.added * 1000).toISOString().replace('T', ' ').slice(0, 19);
        const opId = element.operationId;
        recordData += `<tr>
          <td>${count}</td>
          <td>${date}</td>
          <td>${opId}</td>
          <td>${amount}</td>
        </tr>`;
      }
      const html = `
      <html>
      <body style="margin: 10px">
      <div style="width: 550px"> INVOICE: ${currentInvoice}</div>

      <table style="font-family: Courier; font-size: 8px; width: 550px">
      <tr>
      <td>
      SELLER:<br />
      Infinipi AB<br />
      something<br />
      something else<br />
      </td>
      <td>
      BUYER:<br />
      Random Customer<br />
      blah blah<br />
      something else<br />
      </td>
      </tr>
      </table>
      <br />
      <br />

      <table style="font-family: Courier; font-size: 8px; width: 385px">
      <tr><th>#</th><th>Timestamp</th><th>Operation ID</th><th>Amount</th></tr>
      ${recordData}
      </table>
      </body>
      </html>
      `;
      doc.html(html, {
        async callback(doc) {
          await doc.save('pdffile.pdf');
        },
        margin: 30,
        autoPaging: 'text'
      });
      setCurrentInvoice({});
      setInvoiceData([]);
    }
  }, [currentInvoice, invoiceData]);
  return (
    <>
      <DataTable
        columns={[
          {
            name: 'ID',
            selector: (row: any) => row.id
          },
          {
            name: 'MERCHANT ID',
            selector: (row: any) => row.merchantId
          },
          {
            name: 'CCY',
            selector: (row: any) => row.currency
          },
          {
            name: 'AMOUNT',
            selector: (row: any) => row.amount
          },
          {
            name: 'VOLUME',
            selector: (row: any) => row.volume
          },
          {
            name: 'COUNT',
            selector: (row: any) => row.count
          },
          {
            name: 'STATE',
            selector: (row: any) => row.state
          },
          {
            name: 'CREATED',
            selector: (row: any) =>
              row.created > 0 ? new Date(row.created * 1000).toISOString().replace('T', ' ').slice(0, 19) : 'no date'
          },
          {
            name: 'CLOSED',
            selector: (row: any) =>
              row.closed > 0 ? new Date(row.closed * 1000).toISOString().replace('T', ' ').slice(0, 19) : 'no date'
          },
          {
            name: 'PAID',
            selector: (row: any) =>
              row.finalized > 0
                ? new Date(row.finalized * 1000).toISOString().replace('T', ' ').slice(0, 19)
                : 'no date'
          },
          {
            name: 'DOWNLOAD',
            selector: (row: any) => (
              <>
                <Button
                  disabled={downloadDisabled}
                  onClick={() => {
                    setCurrentInvoice({});
                    setInvoiceData([]);
                    setOffset(0);
                    setDownloadDisabled(true);
                    setCurrentInvoice(row);
                  }}
                >
                  Download
                </Button>
              </>
            )
          }
        ]}
        data={invoices}
      ></DataTable>
    </>
  );
};
export default Invoices;
