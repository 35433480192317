import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Modal, Row, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { DtPicker } from 'react-calendar-datetime-picker';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { Method, Service } from 'shared-components/configuration';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { PAYOUTS_QUERY } from 'shared-components/queries/graphql';
import { useMerchantInfo } from 'shared-components/providers/MerchantInfoProvider';
import { convertUTCtoCET, dateConversionFromCalendar } from 'shared-components/utils';
//import { usePayoutsQueue } from 'shared-components/hooks/useStats';

export const Withdrawals = () => {
  const today = new Date();

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const [dateRange, setDateRange] = useState<any>({
    from: {
      year: yesterday.getFullYear(),
      month: yesterday.getMonth() + 1,
      day: yesterday.getDate()
    },
    to: {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate()
    }
  });

  const [csvGenerator, setCsvGenerator] = useState<boolean>(false);
  const [payouts, setPayouts] = useState<any>([]);
  const [csvPayouts, setCsvPayouts] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [perPage, setPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterModalVisible, setFilterModalVisible] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>(dateConversionFromCalendar(dateRange));
  //const { refreshPayouts } = usePayoutsQueue();
  //const [file, setFile] = useState<File>();
  const { name } = useMerchantInfo();

  const payoutsQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'payouts',
    data: {
      query: PAYOUTS_QUERY()
    },
    auto: false,
    onResponse: (data: any) => {
      const payoutsData = {
        data: {},
        pageInfo: {},
        totalCount: 0
      };
      payoutsData.data = data?.edges
        ?.map((value: any) => {
          const values = value.node;
          values.customer.kyc = JSON.parse(values.customer.kyc);
          return values;
        })
        .sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

      payoutsData.pageInfo = data?.pageInfo;
      payoutsData.totalCount = data.totalCount;
      setPayouts(payoutsData);
    }
  });

  const payoutsCsvQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'payoutsCsv',
    data: {
      query: PAYOUTS_QUERY('payoutsCsv')
    },
    auto: false,
    onResponse: (data: any) => {
      const payoutsData = {
        data: {},
        pageInfo: {},
        totalCount: 0
      };
      payoutsData.data = data?.edges
        ?.map((value: any) => {
          const values = value.node;
          values.customer.kyc = JSON.parse(values.customer.kyc);
          return values;
        })
        .sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

      payoutsData.pageInfo = data?.pageInfo;
      payoutsData.totalCount = data.totalCount;
      setCsvPayouts(payoutsData);
      setCsvGenerator(false);
    },
    onErrors: (_) => {
      setCsvGenerator(false);
    }
  });
  const fetchList = useCallback(
    ({
      first,
      last,
      after,
      before,
      clientAccount,
      currency,
      paymentReference,
      eurAmount,
      sekAmount,
      fromDate,
      toDate,
      status,
      operationId,
      merchantPayoutId
    }: any) => {
      payoutsQuery.execute({
        variables: {
          first,
          last,
          after,
          before,
          clientAccount,
          currency,
          paymentReference,
          eurAmount,
          sekAmount,
          fromDate,
          toDate,
          status,
          operationId,
          merchantPayoutId
        }
      });
    },
    [payoutsQuery]
  );

  const handlePageChange = (page: number) => {
    setLoading(true);
    const totalPages = Math.ceil(payouts.totalCount / perPage);
    if (page === 1) {
      fetchList({ first: perPage, ...filters });
    } else if (page >= totalPages) {
      fetchList({ last: perPage, ...filters });
    } else if (page > currentPage) {
      fetchList({ first: perPage, after: payouts.pageInfo.endCursor, ...filters });
    } else if (page < currentPage) {
      fetchList({ last: perPage, before: payouts.pageInfo.startCursor, ...filters });
    } else {
      fetchList({ first: perPage, after: payouts.pageInfo.endCursor, ...filters });
    }
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, _: any) => {
    setPerPage(newPerPage);
  };

  const generateCsv = useCallback(
    (filters: any) => {
      setCsvGenerator(true);
      payoutsCsvQuery.execute({
        variables: filters
      });
    },
    [payoutsCsvQuery]
  );

  const handleApplyFilters = useCallback(
    (event: any) => {
      event.preventDefault();
      const clientAccount = event.currentTarget.elements.clientAccount.value || undefined;
      const currency = event.currentTarget.elements.currency.value || undefined;
      const paymentReference = event.currentTarget.elements.paymentReference.value || undefined;
      const eurAmount = event.currentTarget.elements.eurAmount.value || undefined;
      const sekAmount = event.currentTarget.elements.sekAmount.value || undefined;
      const vFrom = dateRange?.from || undefined;
      const vTo = dateRange?.to || undefined;
      const fromDate =
        vFrom === undefined
          ? undefined
          : `${vFrom.year}-${vFrom.month.toString().padStart(2, '0')}-${vFrom.day.toString().padStart(2, '0')}`;
      const toDate =
        vTo === undefined
          ? undefined
          : `${vTo.year}-${vTo.month.toString().padStart(2, '0')}-${vTo.day.toString().padStart(2, '0')}`;
      const status = event.currentTarget.elements.status.value || undefined;
      const operationId = event.currentTarget.elements.operationId.value || undefined;
      const merchantPayoutId = event.currentTarget.elements.merchantPayoutId.value || undefined;

      setFilters({
        clientAccount,
        currency,
        paymentReference,
        eurAmount,
        sekAmount,
        fromDate,
        toDate,
        status,
        operationId,
        merchantPayoutId
      });
    },
    [dateRange]
  );
  useEffect(() => {
    setTotalRows(payouts.totalCount);
    setLoading(false);
  }, [payouts]);

  useEffect(() => {
    handlePageChange(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, filters]);

  useEffect(() => {
    if (csvPayouts?.data === undefined || csvPayouts?.data?.length === 0) {
      return;
    }

    const header = [
      'Id',
      'Date',
      'Status',
      'ClientId',
      'Amount',
      'Currency',
      'ActualAmount',
      'ActualCurrency',
      'ExternalReference',
      'Provider',
      'Creditor',
      'RemittanceInformation'
    ];
    const columnCount = header.length;
    const data = csvPayouts.data.map((payout: any) => [
      payout.id || '',
      convertUTCtoCET(payout.createdAt) || '',
      payout.status || '',
      payout.merchant.internalId || '',

      payout.eurAmount === payout.amount ? payout.amount?.toFixed(2) ?? '0' : payout.eurAmount?.toFixed(2) ?? '0',
      payout.eurAmount === payout.amount ? 'SEK' : 'EUR',
      payout.amount === undefined ? '' : payout.amount.toFixed(2),
      payout.currency || '',
      payout.merchantPayoutId || '',
      payout.bankName || 'UNKNOWN',
      payout.clientIban || payout.clientBban,
      payout.paymentReference
    ]);

    const fullData = data.map((row: any) => {
      if (row.length < columnCount) {
        const missingFields = columnCount - row.length;
        return [...row, ...Array.from({ length: missingFields }, () => '')];
      }
      return row;
    });

    const csvData = [header, ...fullData];
    const csvContent = csvData.map((row) => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    let filename = 'payouts_all.csv';

    if (dateRange !== undefined && dateRange?.from !== null && dateRange?.to !== null) {
      const vFrom = dateRange?.from || undefined;
      const vTo = dateRange?.to || undefined;
      const fromDate =
        vFrom === undefined
          ? undefined
          : `${vFrom.year}-${vFrom.month.toString().padStart(2, '0')}-${vFrom.day.toString().padStart(2, '0')}`;
      const toDate =
        vTo === undefined
          ? undefined
          : `${vTo.year}-${vTo.month.toString().padStart(2, '0')}-${vTo.day.toString().padStart(2, '0')}`;

      filename = `payouts_${fromDate}-${toDate}.csv`;
    }
    link.setAttribute('download', filename);
    document.body.append(link);
    link.click();
    link.remove();
    setCsvPayouts({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvPayouts]);
  /*
  useEffect(() => {
    const vFrom = dateRange?.from || undefined;
    const vTo = dateRange?.to || undefined;
    const fromDate =
      vFrom === undefined
        ? undefined
        : `${vFrom.year}-${vFrom.month.toString().padStart(2, '0')}-${vFrom.day.toString().padStart(2, '0')}`;
    const toDate =
      vTo === undefined
        ? undefined
        : `${vTo.year}-${vTo.month.toString().padStart(2, '0')}-${vTo.day.toString().padStart(2, '0')}`;

    setFilters({
      fromDate,
      toDate
    });
    //refreshPayouts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  */
  return (
    <>
      <Modal
        show={filterModalVisible}
        onHide={() => {
          setFilterModalVisible(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleApplyFilters}>
          <Modal.Header closeButton>RFQ Filter</Modal.Header>
          <Modal.Body>
            <FloatingLabel controlId="dateRange" label="" className="mb-2">
              <DtPicker
                type="range"
                onChange={setDateRange}
                initValue={dateRange}
                clearBtn={true}
                yearListStyle="list"
                inputClass="form-control form-control-sm"
                placeholder="Select date range"
              />
            </FloatingLabel>
            <br />
            <FloatingLabel controlId="clientAccount" label="Client account number" className="mb-2">
              <Form.Control
                type="text"
                size="sm"
                placeholder="Client account number"
                defaultValue={filters?.clientAccount}
              />
            </FloatingLabel>
            <FloatingLabel controlId="currency" label="Currency" className="mb-2">
              <Form.Control type="text" size="sm" placeholder="Currency (select)" defaultValue={filters?.currency} />
            </FloatingLabel>
            <FloatingLabel controlId="paymentReference" label="Payment Reference" className="mb-2">
              <Form.Control
                type="text"
                size="sm"
                placeholder="Payment Reference"
                defaultValue={filters?.paymentReference}
              />
            </FloatingLabel>
            <FloatingLabel controlId="eurAmount" label="EUR Amount" className="mb-2">
              <Form.Control type="text" size="sm" placeholder="EUR Amount" defaultValue={filters?.eurAmount} />
            </FloatingLabel>
            <FloatingLabel controlId="sekAmount" label="SEK Amount" className="mb-2">
              <Form.Control type="text" size="sm" placeholder="SEK Amount" defaultValue={filters?.sekAmount} />
            </FloatingLabel>
            <FloatingLabel controlId="status" label="Transaction Status" className="mb-2">
              <Form.Control
                type="text"
                size="sm"
                placeholder="Transaction Status (select)"
                defaultValue={filters?.status}
              />
            </FloatingLabel>
            <FloatingLabel controlId="operationId" label="Operation ID" className="mb-2">
              <Form.Control type="text" size="sm" placeholder="Operation ID" defaultValue={filters?.operationId} />
            </FloatingLabel>
            <FloatingLabel controlId="merchantPayoutId" label="Merchant payout ID" className="mb-2">
              <Form.Control
                type="text"
                size="sm"
                placeholder="Merchant payout ID"
                defaultValue={filters?.merchantPayoutId}
              />
            </FloatingLabel>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="px-4"
              type="submit"
              onClick={() => {
                setFilterModalVisible(false);
              }}
            >
              Apply filters
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <DataTable
        columns={[
          {
            name: 'Created at',
            selector: (row: any) => convertUTCtoCET(row?.['createdAt'])
          },
          {
            name: 'Payout reference',
            selector: (row: any) => row?.['paymentReference']
          },
          {
            name: 'PID',
            selector: (row: any) => row?.['customer']?.['personId']
          },
          {
            name: 'First name',
            selector: (row: any) => row?.['customer']?.['kyc']?.['person']?.['forename']
          },
          {
            name: 'Last name',
            selector: (row: any) => row?.['customer']?.['kyc']?.['person']?.['surname']
          },
          {
            name: 'Customer IBAN/BBAN',
            selector: (row: any) =>
              row?.['clientIban'] !== undefined && row?.['clientIban'].length > 0
                ? row?.['clientIban']
                : row?.['clientBban']
          },
          {
            name: 'Amount',
            selector: (row: any) => `${row?.['amount'].toFixed(2)} ${row?.['currency'].toUpperCase()}`
          },
          {
            name: 'Status',
            selector: (row: any) => row?.['status']
          }
        ]}
        title={
          <Row>
            <Col>Withdrawals</Col>
            <Col sm={3}>
              <Button
                variant="outline"
                onClick={() => {
                  setFilterModalVisible(true);
                }}
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
              <Button
                variant="outline-success"
                size="sm"
                disabled={csvGenerator}
                onClick={() => {
                  generateCsv(filters);
                }}
              >
                {csvGenerator === true && <Spinner size="sm" />} Download CSV
              </Button>
            </Col>
          </Row>
        }
        data={payouts.data}
        pagination
        responsive={true}
        highlightOnHover={true}
        progressPending={loading}
        paginationPerPage={perPage}
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
    </>
  );
};
