import React from 'react';

//import { useApp } from 'shared-components/providers/AppProvider';

import { environment } from 'shared-components/configuration';

import DashboardAdmin from './admin';
import DashboardUser from './merchant';

const Dashboard = () => {
  /*  const { userData } = useApp(); */
  // eslint-disable-next-line no-constant-condition
  return <>{environment.startsWith('admin') ? <DashboardAdmin /> : <DashboardUser />}</>;
};
export default Dashboard;
