import 'bootstrap-daterangepicker/daterangepicker.css';
import { Col, Row } from 'react-bootstrap';
import React, { useState } from 'react';
//import { usePapaParse } from 'react-papaparse';

import { CREATE_BATCHED_CLIENT_PAYOUT } from 'shared-components/queries/mutations';
//import BatchPayoutResults from 'components/BatchPayoutResults';
//import Withdrawbox from '../Withdrawbox';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { Method, Service } from 'shared-components/configuration';

//import style from './style.module.scss';
import 'react-calendar-datetime-picker/dist/style.css';

interface AccountSummaryProps {
  withdrawals?: string;
  deposits?: string;
  balance?: string;
  pendingBalance?: string;
}

const AccountSummary = (_props: AccountSummaryProps) => {
  const [_batchResult, setBatchResult] = useState([]);
  //const { readString } = usePapaParse();
  //const [_bulkData, setBulkData] = useState([]);

  const _query = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'createBatchedClientPayout',
    data: {
      query: CREATE_BATCHED_CLIENT_PAYOUT
    },
    auto: false,
    onResponse: (data: any) => {
      setBatchResult(data.result);
    }
  });
  /*
  const handleBulkPayout = useCallback(
    (file: File) => {
      file.text().then((csvData) => {
        readString(csvData, {
          worker: true,
          header: false,
          skipEmptyLines: true,
          complete: (results: any) => {
            setBulkData(results.data);
          }
        });
      });
    },
    [readString]
  );
*/
  return (
    <>
      <Row>
        <Col>
          {/*
          <Card className={style.card}>
            <Card.Title>Balance</Card.Title>
            <Card.Text>{balance}</Card.Text>
          </Card>
          */}
        </Col>
        <Col>
          {/* 
          <Card className={style.card}>
            <Card.Title>Pending balance</Card.Title>
            <Card.Text>{pendingBalance}</Card.Text>
          </Card>
          */}
        </Col>
      </Row>
      <Row>
        <Col>
          {/*
          <Card className={style.card}>
            <br />
            <Withdrawbox></Withdrawbox>
          </Card>
*/}
        </Col>
        <Col>
          {/*
          <Card className={style.card}>
            <Card.Title></Card.Title>
            <Card.Text>
              Batch payout (<a href={'/files/example.csv'}>CSV template</a>)
              <FileUploader onSelect={handleBulkPayout} onDrop={handleBulkPayout} />
            </Card.Text>
          </Card>*/}
        </Col>
      </Row>
      <Row>
        <Col></Col>
      </Row>
      {/*<BatchPayoutResults data={batchResult} /> */}
    </>
  );
};

export default AccountSummary;
