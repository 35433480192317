import React, { useCallback, useMemo, FormEvent } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';

import { useMerchants } from 'contexts/GraphQL';
import { ADMIN_UPDATE_MERCHANT_MUTATION } from 'shared-components/queries/graphql';
import Button from '../../shared-components/components/Button';
import { AdminPrefix, AdminUrl, Method, Service } from '../../shared-components/configuration';
import useSWQuery from 'shared-components/hooks/useSWQuery';

const MerchantProfileAdmin = ({ data }: { data: any }) => {
  const { merchants, refreshMerchants } = useMerchants();
  const currentMerchant = useMemo(() => {
    return merchants?.find((element) => element.id === data.id);
  }, [data, merchants]);

  const countryList = useMemo(() => {
    countries.registerLocale(en);
    return countries.getNames('en', { select: 'official' });
  }, []);

  const navigate = useNavigate();

  const query = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'updateMerchant',
    data: {
      query: ADMIN_UPDATE_MERCHANT_MUTATION
    },
    auto: false,
    onResponse: (data: any) => {
      if (data.success === true) {
        refreshMerchants();
        navigate(`${AdminPrefix}/${AdminUrl.MERCHANTS}`);
      }
    }
  });

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formElements = event.currentTarget.elements;
      const name = formElements.namedItem('name') as HTMLInputElement;
      const email = formElements.namedItem('email') as HTMLInputElement;
      const iban = formElements.namedItem('iban') as HTMLInputElement;
      const bban = formElements.namedItem('bban') as HTMLInputElement;
      const friendlyName = formElements.namedItem('friendlyName') as HTMLInputElement;
      const internalId = formElements.namedItem('internalId') as HTMLInputElement;
      const accountId = formElements.namedItem('accountId') as HTMLInputElement;
      const country = formElements.namedItem('country') as HTMLInputElement;
      const country3Alpha = countries.alpha2ToAlpha3(country.value);

      const orgNumber = formElements.namedItem('orgNumber') as HTMLInputElement;
      const _fee = formElements.namedItem('fee') as HTMLInputElement;
      const _fxFee = formElements.namedItem('fxfee') as HTMLInputElement;
      const _fixedFee = formElements.namedItem('fixedFee') as HTMLInputElement;
      const _depositFee = formElements.namedItem('depositFee') as HTMLInputElement;
      const _payoutFee = formElements.namedItem('payoutFee') as HTMLInputElement;
      const _withdrawFee = formElements.namedItem('withdrawFee') as HTMLInputElement;
      const submitter = (event.nativeEvent as SubmitEvent).submitter as HTMLButtonElement;
      query.execute({
        variables: {
          id: atob(data?.id).split(':')[1],
          name: name.value,
          email: email.value,
          iban: iban.value,
          bban: bban.value,
          friendlyName: friendlyName.value,
          internalId: internalId.value,
          accountId: accountId.value,
          orgNumber: orgNumber.value,
          country: country3Alpha,
          status: submitter.value === 'update'
        }
      });
    },
    [data?.id, query]
  );

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="loginForm.NameInput">
          <Form.Label>Company Name</Form.Label>
          <Form.Control name="name" type="text" placeholder="Company name" value={currentMerchant?.name} disabled />
        </Form.Group>

        <FloatingLabel controlId="friendlyName" label="PI Friendly name" className="mb-2">
          <Form.Control
            type="text"
            size="sm"
            placeholder="PI Friendly name"
            defaultValue={currentMerchant?.friendlyName}
          />
        </FloatingLabel>
        <FloatingLabel controlId="internalId" label="Merchant Internal ID" className="mb-2">
          <Form.Control
            type="text"
            size="sm"
            placeholder="Merchant Internal ID"
            defaultValue={currentMerchant?.internalId}
          />
        </FloatingLabel>

        <FloatingLabel controlId="email" label="Email address" className="mb-2">
          <Form.Control type="email" size="sm" placeholder="Email Address" defaultValue={currentMerchant?.email} />
        </FloatingLabel>

        <FloatingLabel controlId="iban" label="Set IBAN number" className="mb-2">
          <Form.Control type="text" size="sm" placeholder="IBAN number" defaultValue={currentMerchant?.iban} />
        </FloatingLabel>
        <FloatingLabel controlId="bban" label="Set BBAN number" className="mb-2">
          <Form.Control type="text" size="sm" placeholder="BBAN number" defaultValue={currentMerchant?.bban} />
        </FloatingLabel>
        <FloatingLabel controlId="accountId" label="Set Multitude Account ID" className="mb-2">
          <Form.Control
            type="text"
            size="sm"
            placeholder="Multitude Account ID"
            defaultValue={currentMerchant?.accountId}
          />
        </FloatingLabel>
        <FloatingLabel controlId="orgNumber" label="Set Organization number" className="mb-2">
          <Form.Control
            type="text"
            size="sm"
            placeholder="Organization number"
            defaultValue={currentMerchant?.orgNumber}
          />
        </FloatingLabel>
        <FloatingLabel controlId="country" label="Merchant country" className="mb-2">
          <Form.Select name="country" aria-label="">
            <option>Choose merchant country</option>
            {Object.keys(countryList).map((value, key) => (
              <option
                key={key}
                value={value}
                selected={value === countries.alpha3ToAlpha2(currentMerchant?.country ?? 'SWE')}
              >
                {countryList?.[value]}
              </option>
            ))}
          </Form.Select>
        </FloatingLabel>

        <Form.Group className="mb-3" controlId="loginForm.BuyFeeInput">
          <Form.Label>Buy Fee percentage</Form.Label>
          <Form.Control name="fee" type="number" placeholder="" step={0.01} defaultValue={currentMerchant?.fee} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="loginForm.FixedFee">
          <Form.Label>Fixed deposit fee (EUR)</Form.Label>
          <Form.Control
            name="fixedFee"
            type="number"
            placeholder=""
            step={0.01}
            defaultValue={currentMerchant?.fixedFee}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="loginForm.FXFeeInput">
          <Form.Label>FX fee percentage</Form.Label>
          <Form.Control name="fxfee" type="number" placeholder="" step={0.01} defaultValue={currentMerchant?.fxFee} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="loginForm.DepositFee">
          <Form.Label>Deposit fee</Form.Label>
          <Form.Control
            name="depositFee"
            type="number"
            placeholder=""
            step={0.01}
            defaultValue={currentMerchant?.depositFee}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="loginForm.PayoutFee">
          <Form.Label>Pay-out fee</Form.Label>
          <Form.Control
            name="payoutFee"
            type="number"
            placeholder=""
            step={0.01}
            defaultValue={currentMerchant?.payoutFee}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="loginForm.WithdrawFee">
          <Form.Label>Withdrawal fee percentage</Form.Label>
          <Form.Control
            name="withdrawFee"
            type="number"
            placeholder=""
            step={0.01}
            defaultValue={currentMerchant?.withdrawFee}
          />
        </Form.Group>
        <center>
          {data.id ? (
            <Row>
              <Col className="d-flex justify-content-center gap-3">
                <Button type="submit" name="task" value="disable" variant="dark">
                  Disable merchant
                </Button>
                <Button type="submit" name="task" value="update">
                  Update data
                </Button>
              </Col>
            </Row>
          ) : (
            <Button>Save merchant</Button>
          )}

          <br />
          <br />
        </center>
      </Form>
    </>
  );
};

export default MerchantProfileAdmin;
