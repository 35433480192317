import { Col, Row } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';

import Button from '../../../shared-components/components/Button';
import { useMerchants } from '../../../contexts/GraphQL';
import SubMerchantAddModal from 'components/Modals/SubMerchantAdd/subMerchantAdd';

const SubMerchants = () => {
  const [addSubMerchantVisible, setAddSubMerchantVisible] = useState(false);
  const [merchantId, setMerchantId] = useState('');
  const { subMerchants, refreshSubMerchants } = useMerchants();

  const handleSubCloseModal = useCallback(() => {
    setAddSubMerchantVisible(false);
  }, []);

  const handleAddMerchant = useCallback(() => {
    setMerchantId('');
    setAddSubMerchantVisible(true);
  }, []);

  const handleAddSubMerchantSuccess = () => {
    refreshSubMerchants();
    setAddSubMerchantVisible(false);
  };

  useEffect(() => {
    refreshSubMerchants();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns: TableColumn<any>[] = [
    {
      name: 'id',
      selector: (row) => row.id || '',
      wrap: true
      //      minWidth: '132px'
    },
    {
      name: 'Name',
      selector: (row) => row.name ?? '',
      wrap: true
    },
    {
      name: 'Email',
      selector: (row) => row.email ?? '',
      wrap: true
    },
    {
      name: 'Merchants',
      selector: (row) => {
        return (
          <>
            {row.merchants.map((value: any) => {
              return (
                <>
                  {value.name}
                  <br />
                </>
              );
            })}
          </>
        );
      },
      wrap: true
    }
  ];
  return (
    <>
      <SubMerchantAddModal
        visible={addSubMerchantVisible}
        handleClose={handleSubCloseModal}
        onSuccess={handleAddSubMerchantSuccess}
        merchantId={merchantId}
      ></SubMerchantAddModal>

      <Row>
        <Col>
          <Button onClick={handleAddMerchant}>Add Sub Merchant</Button>
        </Col>
      </Row>
      <br />
      <h3>Sub Merchants</h3>
      <DataTable
        responsive={false}
        highlightOnHover={true}
        columns={columns}
        pagination
        onRowClicked={(row: any) => {
          setMerchantId(row.id);
          setAddSubMerchantVisible(true);
        }}
        data={subMerchants ?? []}
        customStyles={{
          headCells: {
            style: {
              backgroundColor: 'lightgray',
              textTransform: 'uppercase'
            }
          }
        }}
        className="shadow p-3 mb-5 bg-white overflow-auto"
      />
    </>
  );
};
export default SubMerchants;
