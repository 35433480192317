import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { Chart } from 'react-google-charts';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Form } from 'react-bootstrap';

import { format, subDays, eachDayOfInterval } from 'date-fns';
import clsx from 'clsx';

import { useOrchStats } from 'shared-components/hooks/useStats';
import { useMerchants } from 'contexts/GraphQL';

const calculateAge = (ssn: string) => {
  const year = Number.parseInt(ssn.slice(0, 4), 10);
  const month = Number.parseInt(ssn.slice(4, 6), 10) - 1; // JS months are 0-based
  const day = Number.parseInt(ssn.slice(6, 8), 10);

  const birthDate = new Date(year, month, day);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

export const DailyStats = () => {
  const { merchants } = useMerchants();
  const merchantRef = useRef<HTMLSelectElement>(null);

  const { stats, merchant, setMerchant, setDaysBehind } = useOrchStats();
  const days = 30;

  const handleMerchantSwitch = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const merchantId: any = event.currentTarget.value;
      setMerchant(atob(merchantId).split(':')[1]);
    },
    [setMerchant]
  );

  const columns: TableColumn<any>[] = [
    {
      name: 'Person ID',
      selector: (row) => row.personId || '',
      wrap: true
      //      minWidth: '132px'
    },
    {
      name: 'Volume',
      selector: (row) => `${Number.parseFloat(row.total).toFixed(2)} SEK ` || '',
      wrap: true
      //      minWidth: '132px'
    }
  ];

  const pidHallOfFame = useMemo(() => {
    return (
      stats.map((person: any) => {
        const total = person.volumes.reduce(
          (sum: any, day: any) =>
            sum + day.volumes.reduce((subSum: any, volume: any) => subSum + Number.parseFloat(volume.total), 0),
          0
        );

        const totalCount = person.volumes.reduce(
          (sum: any, day: any) => sum + day.volumes.reduce((subSum: any, volume: any) => subSum + volume.count, 0),
          0
        );

        return {
          personId: person.personId,
          total,
          totalCount,
          age: calculateAge(person.personId)
        };
      }) ?? []
    );
  }, [stats]);

  const volumeChart = useMemo(() => {
    const today = new Date();
    const firstDay = subDays(today, days);

    const result: Record<string, number> = {};

    for (const person of stats) {
      for (const entry of person.volumes) {
        const day = entry.day;
        const totalVolume = entry.volumes.reduce((sum: any, volume: any) => sum + Number.parseFloat(volume.total), 0);
        result[day] = (result[day] || 0) + totalVolume;
      }
    }

    const allDays = eachDayOfInterval({ start: firstDay, end: today }).map((date) => format(date, 'yy-MM-dd'));

    const fullResult = allDays.map((day) => [day, result[day] || 0]);

    return fullResult;
  }, [stats]);

  const last7DaysVolumeChart = useMemo(() => {
    return volumeChart.slice(-7);
  }, [volumeChart]);

  const countChart = useMemo(() => {
    const today = new Date();
    const firstDay = subDays(today, days);

    const result: Record<string, number> = {};

    for (const person of stats) {
      for (const entry of person.volumes) {
        const day = entry.day;
        const totalCount = entry.volumes.reduce((sum: any, count: any) => sum + count.count, 0);
        result[day] = (result[day] || 0) + totalCount;
      }
    }

    const allDays = eachDayOfInterval({ start: firstDay, end: today }).map((date) => format(date, 'yy-MM-dd'));

    const fullResult = allDays.map((day) => [day, result[day] || 0]);

    return fullResult;
  }, [stats, days]);

  const ageGroups = useMemo(() => {
    const groups: Record<string, number> = {
      '0-10': 0,
      '11-20': 0,
      '21-30': 0,
      '31-40': 0,
      '41-50': 0,
      '51-60': 0,
      '61-70': 0,
      '71+': 0
    };

    // Assign each person to the correct group
    for (const { age } of pidHallOfFame) {
      if (age >= 0 && age <= 10) groups['0-10']++;
      else if (age >= 11 && age <= 20) groups['11-20']++;
      else if (age >= 21 && age <= 30) groups['21-30']++;
      else if (age >= 31 && age <= 40) groups['31-40']++;
      else if (age >= 41 && age <= 50) groups['41-50']++;
      else if (age >= 51 && age <= 60) groups['51-60']++;
      else if (age >= 61 && age <= 70) groups['61-70']++;
      else groups['71+']++;
    }

    return Object.entries(groups);
  }, [pidHallOfFame]); // Recalculate only when `pidHallofFame` changes

  useEffect(() => {
    setDaysBehind(30);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <Form.Floating className={clsx('mb-3')}>
        <Form.Select onChange={handleMerchantSwitch} aria-label="" ref={merchantRef}>
          <option>Select current merchant</option>
          {Object.keys(merchants).map((value: any, key) => {
            const id = merchants[value]['id'];
            return (
              <option key={key} value={merchants[value]['id']} selected={merchant === atob(id ?? '').split(':')[1]}>
                {merchants[value]['name']}
              </option>
            );
          })}
        </Form.Select>
        <label htmlFor={'merchantList'}>{'Stats for merchant'}</label>
      </Form.Floating>
      Unique pids: {pidHallOfFame.length}
      <br />
      <Chart
        // Try different chart types by changing this property with one of: LineChart, BarChart, AreaChart...
        chartType="ColumnChart"
        data={[['Date', 'Volume'], ...last7DaysVolumeChart]}
        options={{
          title: 'Last 7 days volume divided by date'
        }}
        legendToggle
      />
      <br />
      <br />
      <Chart
        // Try different chart types by changing this property with one of: LineChart, BarChart, AreaChart...
        chartType="ColumnChart"
        data={[['Date', 'Volume'], ...volumeChart]}
        options={{
          title: 'Monthly volume divided by date'
        }}
        legendToggle
      />
      <br />
      <br />
      <Chart
        // Try different chart types by changing this property with one of: LineChart, BarChart, AreaChart...
        chartType="ColumnChart"
        data={[['Date', 'Count'], ...countChart]}
        options={{
          title: 'Monthly transaction count divided by date'
        }}
        legendToggle
      />
      <br />
      <br />
      <Chart
        chartType="PieChart"
        data={[['Age', 'Count'], ...ageGroups]}
        options={{
          title: 'Age stats'
        }}
        legendToggle
      />
      <br />
      <br />
      <DataTable
        responsive={true}
        highlightOnHover={true}
        columns={columns}
        pagination
        data={pidHallOfFame ?? []}
        customStyles={{
          headCells: {
            style: {
              backgroundColor: 'lightgray',
              textTransform: 'uppercase'
            }
          }
        }}
        className="shadow p-3 mb-5 bg-white overflow-auto"
      />
    </>
  );
};
