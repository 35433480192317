import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { MerchantTransaction, MerchantType } from '../types/grapql';
import { BaseProps } from '../shared-components/utils/types';
import { MERCHANTS_QUERY, SUB_MERCHANTS } from 'shared-components/queries/graphql';
import { Method, Service, environment } from 'shared-components/configuration';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { useApp } from 'shared-components/providers/AppProvider';

const initialMerchantTx: MerchantTransaction = {
  id: 0,
  cashTransferRequestId: 0,
  glBatchId: 0,
  tradeId: 0,
  dividendId: 0,
  fee: false,
  description: '',
  amount: '0',
  settlementDate: '',
  tradeDate: '',
  currencyId: 0,
  accountId: '',
  settlementId: 0,
  entityId: 0,
  ruleFeeId: 0,
  futureSet: false,
  reservationStatus: false,
  futuresMatched: 0,
  refKey1: '',
  refKey2: '',
  refValue1: '',
  refValue2: '',
  txId: 0,
  address: '',
  clientRef: '',
  extWalletKey: '',
  source: '',
  sourceRef: '',
  sourceData: '',
  sourced: ''
};

const MerchantEmail = createContext<string>('');
const MerchantIban = createContext<string>('');
const MerchantCounterpartyId = createContext<string>('');
const MerchantEntity = createContext<string>('');
const MerchantTx = createContext<MerchantTransaction>(initialMerchantTx);
const Merchants = createContext({
  merchants: [] as MerchantType[],
  subMerchants: [] as any[],
  refreshMerchants: () => {
    // EMPTY METHOD
  },
  refreshSubMerchants: () => {
    // EMPTY METHOD
  }
});

export const useMerchantEmail = () => {
  const context = useContext(MerchantEmail);
  if (!context) throw new Error('MerchantEmail not found');
  return context;
};

export const useMerchantIban = () => {
  const context = useContext(MerchantIban);
  if (!context) throw new Error('MerchantIban not found');
  return context;
};

export const useMerchantCounterpartyId = () => {
  const context = useContext(MerchantCounterpartyId);
  if (!context) throw new Error('MerchantCounterpartyId not found');
  return context;
};

export const useMerchantEntity = () => {
  const context = useContext(MerchantEntity);
  if (!context) throw new Error('MerchantEntity not found');
  return context;
};

export const useMerchantTx = () => {
  const context = useContext(MerchantTx);
  if (!context) throw new Error('MerchantTx not found');
  return context;
};

export const useMerchants = () => {
  const context = useContext(Merchants);
  if (!context) throw new Error('Merchants not found');
  return context;
};

export const MerchantsProvider = ({ children }: BaseProps) => {
  const {
    values: { loggedIn }
  } = useApp();

  const [merchants, setMerchants] = useState<any[]>([]);
  const [subMerchants, setSubMerchants] = useState<any[]>([]);
  const merchantsQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'merchants',
    data: {
      query: MERCHANTS_QUERY
    },
    auto: false,
    onResponse: (data: any) => {
      const merchants = {
        data: [],
        pageInfo: {},
        totalCount: 0
      };

      merchants.data = data?.edges?.map((value: any) => {
        return value.node;
      });
      merchants.pageInfo = data?.pageInfo;
      merchants.totalCount = data.totalCount;
      //        .sort((a: any, b: any) => (a.createdAt > b.createdAt ? -1 : 1));

      setMerchants(merchants.data ?? []);
    }
  });

  const subMerchantsQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'subMerchants',
    data: {
      query: SUB_MERCHANTS
    },
    auto: false,
    onResponse: (data: any) => {
      const submerchants = {
        data: [],
        pageInfo: {},
        totalCount: 0
      };

      submerchants.data = data?.edges?.map((value: any) => {
        const val = value.node;
        val.id = atob(value.node.id).split(':')[1];
        val.merchants = value.node.merchants.edges.map((mv: any) => {
          const mval = mv.node;
          mval.id = atob(mv.node.id).split(':')[1];
          return mval;
        });
        return value.node;
      });
      submerchants.pageInfo = data?.pageInfo;
      submerchants.totalCount = data.totalCount;
      //        .sort((a: any, b: any) => (a.createdAt > b.createdAt ? -1 : 1));

      setSubMerchants(submerchants.data ?? []);
    }
  });

  const refreshMerchants = useCallback(() => {
    if (environment.startsWith('admin')) {
      merchantsQuery.execute();
    }
  }, [merchantsQuery]);

  const refreshSubMerchants = useCallback(() => {
    if (environment.startsWith('admin')) {
      subMerchantsQuery.execute();
    }
  }, [subMerchantsQuery]);

  useEffect(() => {
    if (loggedIn) {
      refreshMerchants();
    }
  }, [loggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Merchants.Provider value={{ merchants, subMerchants, refreshMerchants, refreshSubMerchants }}>
        {children}
      </Merchants.Provider>
    </>
  );
};
