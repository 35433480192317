import { Col, Container, Form, InputGroup, Navbar } from 'react-bootstrap';
import React, { ChangeEvent, createRef, useCallback, useEffect, /*useMemo, */ useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

//import { EUR } from '../../../shared-components/assets/icons';
//import Icon from '../../Icon';
//import { useApp } from 'shared-components/providers/AppProvider';
//import { useBalances } from '../../../contexts/balances';
import { CanvasMenu } from '../SideBar/canvasSlideBar';
import { useApp } from 'shared-components/providers/AppProvider';

import style from './style.module.scss';

const Header = () => {
  const timeoutHandler = useRef<any>(null);
  const searchRef = createRef<HTMLInputElement>();
  const {
    values: { searchBox },
    setters: { setSearchBox }
  } = useApp();

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const searchValue = event.target.value;
      if (timeoutHandler.current) clearTimeout(timeoutHandler.current);
      timeoutHandler.current = setTimeout(() => {
        setSearchBox(searchValue);
      }, 1000);
    },
    [setSearchBox]
  );
  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.value = searchBox ?? ' ';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBox]);
  /*
  const balances = useBalances();

  const eurBalance = useMemo(() => {
    const broker = balances?.balances?.filter((account: any) => {
      return account.accountType === 'Broker';
    });
    if (!broker) {
      return;
    }
    const amount = broker[0]?.balance?.filter((balance: any) => {
      return balance.currencyName === 'EURX';
    })?.[0]?.amount;
    return Number.parseFloat(amount).toFixed(2);
  }, [balances?.balances]);
*/
  return (
    <>
      <Navbar className={style.mainheader} expand="lg" /*bg={appContext.theme} variant={appContext.theme} */>
        <Container fluid className={style.container}>
          <Col>
            <div style={{ display: 'flex' }}>
              <CanvasMenu />
            </div>
          </Col>
          <Col xs={4} sm={3} md={3} lg={2} xl={3}>
            <Form>
              <InputGroup size="sm" className="p-0">
                <InputGroup.Text id="search">
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  ref={searchRef}
                  onChange={onChangeHandler}
                />
              </InputGroup>
            </Form>
          </Col>
          {!(/*appContext.userData.isAdmin*/ false) && (
            <>
              {/*
              <Col xs={4} sm={3} md={3} lg={2} xl={2} className={style['currency-balance']}>
                <Icon icon={EUR} style={{ height: '30px' }} />
                {eurBalance} EUR
              </Col>
            */}
            </>
          )}
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
